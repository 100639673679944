.projects-head-image {
    min-height: 250px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 3rem;
    color: white;
    position: relative;
  }

  .projects-head-image::after {
    content: ""; 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    background-image: url('../../../assets/images/BarwonEdge_14_websize.jpg');
    background-size: cover;
    background-position: 0 -15rem;
    filter: brightness(0.65);
    z-index: -1;
  }

  .projects-head-image h1 {
    font-size: 4rem;
    margin-left: 40px;
  }

  @media (max-width: 768px) {
    .projects-head-image h1 {
      margin: 0 auto;
    }
  }

  @media (max-width: 425px) {
    .projects-head-image {
      min-height: 150px;
    }

    .projects-head-image h1 {
      font-size: 2rem;
    }
  }