.services-summary {
    padding: 2.5rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0;
    background-color: #eeeeee;
  }

  .services-summary h2 {
    font-size: medium;
  }

  .read-more-link {
    text-decoration: none;
    color: inherit;
  }
  
  .read-more-link:hover h2 {
    text-decoration: underline;
  }

  .services-summary h1 {
    font-size: 3rem;
    margin: 0;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* 6 equal columns */
    grid-template-rows: auto auto; /* Two rows */
    gap: 15px; /* Gap between grid items */
    width: 75%;
    margin: 2rem auto;
  }
  
  .service-box {
    background-color: #ffffff; /* Light background color for boxes */
    padding: 1.5rem;
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    align-content: center;
  }

  .service-box-1, .service-box-2 {
    grid-column: span 3; /* Span 3 columns */
  }

  .service-box-3, .service-box-4, .service-box-5 {
    grid-column: span 2; /* Span 3 columns */
  }
  
  .service-box h3 {
    margin-bottom: 0.5rem;
  }

  .service-box p {
    color: #717070;
    font-size: 0.9rem;
  }

  .service-box h2 {
    margin-top: 1.5rem;
    font-size: 0.85rem;
  }
  
  .services-logo {
    height: 6rem;
    max-height: 75px;
  }

  @media (max-width: 975px) {
    .services-summary {
      background-color: white;
    }

    .services-grid {
      width: 90%;
    }

    .service-box {
      background-color: white;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    }

    .service-box p {
      display: none;
    }
  }

  @media (max-width: 425px) {
    .services-logo {
      height: 3rem;
    }

    .services-grid {
      grid-template-rows: auto auto auto; /* Adding extra row */
    }

    .service-box {
      padding: 1rem;
    }

    .service-box-1, .service-box-2 {
      grid-column: span 3; /* Span 3 columns */
    }
  
    .service-box-3, .service-box-4 {
      grid-column: span 3; /* Span 3 columns */
    }

    .service-box-5 {
      grid-column: span 6;
    }

    .services-summary h1 {
      font-size: 2.5rem;
      text-align: center;
      padding: 0.5rem 2rem;
    }

    .service-box h3 {
      font-size: 1rem;
      text-align: center;
      margin-bottom: 0.5rem;
    }
  }

  @media (max-width: 375px) {
    .services-grid {
      grid-template-rows: auto auto auto auto auto; /* Adding extra row */
    }

    .service-box-1, .service-box-2, .service-box-3, .service-box-4 {
      grid-column: span 6;
    }
  }