.projects-content {
    padding: 2rem 4rem;
  }
  
  .projects-content h1 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    color: #333;
  }

  .projects-logo {
    display: flex;
  }

  .projects-logo img {
    height: 10vw;
    width: auto;
    margin: 3rem auto;
  }
  
  .project-images {
    display: flex;
    gap: 1rem;
    height: 35vw;
  }
  
  .project-images img {
    width: 32%;
    height: auto;
    object-fit: cover;
  }
  
  .project-description {
    text-align: left;
    font-size: 1rem;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .project-description h2 {
    font-size: 2rem;
  }

  .project-description h4 {
    margin-top: 0.5rem;
  }
  
  @media (max-width: 425px) {
    .projects-content {
      padding: 1rem 2rem;
    }

    .projects-logo img {
      height: 15vw;
      margin: 1rem auto;
    }

    .project-description {
      font-size: 0.75rem;
    }
  
    .project-description h2 {
      font-size: 1.25rem;
    }
  
    .project-description h4 {
      margin-top: 0;
    }
  }