.our-services-head-image {
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 3rem;
    color: white;
    position: relative;
  }

  .our-services-head-image::after {
    content: ""; 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    background-image: url('../../../assets/images/RS101185_websize.jpg');
    background-size: cover;
    background-position: center;
    filter: brightness(0.75);
    z-index: -1;
  }

  .our-services-head-image h1 {
    font-size: 4rem;
    margin-left: 40px;
  }

  .our-services-head-text {
    background-color: #f9f4f4;
    min-height: 25vh;
    align-content: center;
  }

  .our-services-head-text p {
    margin: 0 auto;
    width: 60%;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 2rem;
  }

  @media (max-width: 768px) {
    .our-services-head-image h1 {
      margin: 0 auto;
    }
  }

  @media (max-width: 425px) {
    .our-services-head-image {
      min-height: 150px;
    }

    .our-services-head-image h1 {
      margin: 0 auto;
      font-size: 2rem;
    }

    .our-services-head-text p {
      margin: 2rem auto;
      width: 90%;
      font-size: 0.8rem;
      line-height: 1.5rem;
    }
  }