.site-footer {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.black-footer {
  background-color: #000;
  color: #fff;
  padding: 2rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.black-footer-content {
  display: flex;
  justify-content: space-between;
  width: 90%;
  max-width: 1200px; /* Limit maximum width */
  gap: 30%; /* Adjust spacing between sections */
  border-bottom: 0.5px solid #fff;
}

.service-areas,
.contact-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 2rem;
}

.service-areas {
  text-align: left;
  color: #fff;
}

.contact-details {
  text-align: right;
}

.service-areas h2,
.contact-details h2 {
  margin-bottom: 1rem;
}

.contact-links {
  display: flex;
  justify-content: center;
  gap: 25px;
  margin: 1rem 0 0 0;
}

.contact-logo {
  height: 25px;
  width: 25px;
  margin-left: 20px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}

.footer-content img {
  max-height: 4rem;
}

.footer-content p {
  font-size: 0.8rem;
  color: #2994a8;
  text-align: center;
  margin: 0;
}

.footer-link {
  color: #fff;
}

.home-link {
  margin-top: 4.2rem;
  margin-bottom: 1rem;
  height: 20px;
}

@media (max-width: 975px) {
  .black-footer-content {
    display: flex;
    justify-content: space-between;
    gap: 10%; /* Adjust spacing between sections */
    border-bottom: 0.5px solid #fff;
  }
}

@media (max-width: 425px) {
  .black-footer-content {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    border-bottom: 0.5px solid #fff;
  }

  .service-areas {
    display: none;
  }

  .contact-details {
    text-align: center;
  }

  .contact-links {
    margin-top: 2rem;
  }
}
