.hero {
    min-height: 50vh; /* Or a fixed height */
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3rem;
    color: white;
    position: relative;
  }
  
  .hero::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/images/House1.jpg');
    background-size: cover;
    background-position: center;
    filter: brightness(0.5);
    background-color: rgba(0, 0, 0, 0.3); /* Add background overlay */
    mix-blend-mode: multiply; /* Blend the overlay with the image */
    z-index: -1;
  }
  
  .hero-logo {
    height: 150px;
    min-height: 25px;
    margin: 0;
  }
  
  .hero h1 {
    font-size: 50px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .hero p {
    line-height: 25px;
    font-size: 20px;
    font-weight: 400;
    width: 40%;
    text-align: left;
    margin: 0;
    padding: 5px;
    color: #f0f0f0; /* Improve text contrast */
  }
  
  .hero button {
    background-color: #ffffff;
    color: #000000;
    margin-top: 25px;
    padding: 18px 42px;
    border: 2px solid #ffffff;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
  
  .hero button:hover {
    background-color: transparent;
    color: #ffffff;
  }
  
  @media (max-width: 975px) {
    .hero {
      align-items: center;
      max-width: 100%;
    }
  
    .hero-logo {
      margin-top: 5vh;
      height: auto;
      max-width: 100%;
    }
  
    .hero h1 {
      text-align: center;
    }
  
    .hero p {
      display: block;
      width: 80%;
      font-size: 16px;
      text-align: center;
    }
  }